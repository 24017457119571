import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    {
      src: "/Images/WhatsApp Image 2024-11-12 at 10.23.00 PM.jpeg",
      alt: "Sleek and contemporary, the Black WPC frame complements any interior with a bold, modern touch. Known for its exceptional stability and durability, it’s ideal for long-term performance in various settings.",
      description: "Black",
    },
    {
      src: "/Images/WhatsApp Image 2024-11-12 at 10.23.06 PM.jpeg",
      alt: "Our Coco Brown WPC frames offer a warm and sophisticated touch to any setting. Engineered for durability and water resistance, they provide a stylish and long-lasting solution for modern interiors.",
      description: "Coco Brown",
    },
    {
      src: "/Images/WhatsApp Image 2024-11-12 at 10.23.08 PM.jpeg",
      alt: "This deep Chocolate Brown WPC frame is perfect for adding a rich, elegant accent to doorways. It combines high stability and termite resistance, ensuring both beauty and resilience.",
      description: "Chocolate Brown",
    },
    {
      src: "/Images/WhatsApp Image 2024-11-12 at 10.23.09 PM.jpeg",
      alt: "The Teekwood WPC frame brings a classic wood-like finish with the added benefits of waterproofing and anti-termite properties. It’s a durable, eco-friendly choice for a natural aesthetic.",
      description: "Teekwood",
    },
  ];

  useEffect(() => {
    AOS.init({ duration: 1000, easing: "ease-in-out" });
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [images.length]);

  const goToPrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const navigate = useNavigate();

  return (
    <div className="bg-gradient-to-b from-indigo-50 via-purple-50 to-pink-50 sm:w-[100%] w-[90%] mx-auto">
      <div className="mx-auto  sm:h-[700px] sm:w-[100%] px-4 sm:px-6 lg:px-8 py-12">
        {/* Carousel Container */}
        <div
          className="h-[auto]  relative rounded-xl bg-white/80 backdrop-blur-sm shadow-2xl p-4 sm:p-6 border border-indigo-100"
          data-aos="fade-up"
        >
          {/* Images Section */}
          <div className="relative overflow-hidden my-4">
            <div className="flex justify-center items-center space-x-4 sm:space-x-6">
              {images.map((image, index) => {
                const isActive = index === currentIndex;
                return (
                  <div
                    key={index}
                    className={`transform transition-all duration-500 ease-in-out ${
                      isActive
                        ? "w-1/2 sm:w-1/3 opacity-100 scale-100"
                        : "w-1/4 sm:w-1/5 opacity-50 scale-90"
                    }`}
                  >
                    <img
                      src={image.src}
                      alt={image.alt}
                      className={`rounded-lg shadow-lg transition-all duration-500 ${
                        isActive
                          ? "ring-4 ring-indigo-400 ring-opacity-50"
                          : ""
                      }`}
                    />
                  </div>
                );
              })}
            </div>

            {/* Navigation Buttons */}
            <button
              onClick={goToPrevious}
              aria-label="Previous Image"
              className="absolute left-[-5%] sm:left-4 top-1/2 -translate-y-1/2 bg-white/20 hover:bg-indigo-50 rounded-full p-2 sm:p-3 shadow-lg transition-all duration-300 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-indigo-400 border border-indigo-100"
            >
              <ChevronLeft className="lg:w-6 lg:h-6 md:w-4 md:h-4 w-2 sm:h-2 text-indigo-900" />
            </button>

            <button
              onClick={goToNext}
              aria-label="Next Image"
              className="absolute right-[-5%] sm:right-4 top-1/2 -translate-y-1/2 bg-white/20 hover:bg-indigo-50 rounded-full p-2 sm:p-3 shadow-lg transition-all duration-300 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-indigo-400 border border-indigo-100"
            >
              <ChevronRight className="lg:w-6 lg:h-6 md:w-4 md:h-4 w-2 sm:h-2 text-indigo-900" />
            </button>
          </div>

          {/* Content Section */}
          <div
            className="mt-6 sm:mt-8 grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 px-4"
            data-aos="fade-up"
          >
            <div className="space-y-2 sm:space-y-4">
              <h2 className="text-xl sm:text-3xl font-semibold bg-gradient-to-r from-indigo-900 to-purple-900 bg-clip-text text-transparent leading-tight">
                {images[currentIndex].alt}
              </h2>
              <div className="h-1 w-16 sm:w-20 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full" />
            </div>
            <div className="space-y-4">
              <p className="text-lg sm:text-2xl text-indigo-900 font-semibold leading-relaxed">
                Color: {images[currentIndex].description}
              </p>
              <button
                onClick={() => navigate("/About")}
                className="group inline-flex items-center px-4 py-2 sm:px-6 sm:py-3 text-sm sm:text-lg font-semibold text-white bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Know more
                <span className="ml-2 transform transition-transform group-hover:translate-x-1">
                  →
                </span>
              </button>
            </div>
          </div>

          {/* Progress Indicators */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 mb-[-30px]">
            {images.map((_, index) => (
              <div
                key={index}
                className={`h-2 rounded-full transition-all duration-300 ${
                  index === currentIndex
                    ? "w-6 sm:w-8 bg-gradient-to-r from-indigo-500 to-purple-500"
                    : "w-2 bg-black"
                }`}
              />
            ))}
          </div>
        </div>
      </div>
      <hr className="my-8 border border-black" />
      <div
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 w-full px-4 sm:w-[90%] md:w-[80%] mx-auto"
          data-aos="fade-up"
        >
          {[
            {
              img: '/Images/4.png',
              title: 'Moulding',
              sizes: '60x30mm, 75x50mm, 90x45mm, 100x63mm, 125x63mm',
            },
            {
              img: '/Images/3.png',
              title: 'Full Pain',
              sizes: '100x63mm',
            },
            {
              img: '/Images/2.png',
              title: 'Moulding With Back Side Rebate',
              sizes: '75x50mm, 100x63mm, 125x63mm',
            },
            {
              img: '/Images/1.png',
              title: 'Double Rabat With Back Side Rebate',
              sizes: '150x63mm',
            },
          ].map((item, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <img src={item.img} alt="" className="w-[80%] sm:w-[80%] md:w-[90%] object-contain" />
              <div className="px-4 mt-4">
                <h2 className="font-bold text-md sm:text-lg md:text-xl">{item.title}</h2>
                <p className="text-sm sm:text-md md:text-lg mt-2">
                  <span className="font-semibold">Available Sizes:</span> <br /> {item.sizes}
                </p>
              </div>
            </div>
          ))}
        </div>

<hr className="my-8 border border-black" />

<div className="text-center my-10 px-4" data-aos="fade-up">
  <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-indigo-900 mb-6">
    WHY CHOOSE ANTIQUE PLAST
  </h1>
  <div className="text-sm md:text-base lg:text-lg text-indigo-800 space-y-4">
    <p>Advanced infrastructure, Superior quality products, Experienced workforce</p>
    <p>Ethical business policies</p>
    <p>Timely delivery, Market-leading prices</p>
    <p>
      Our dedicated team prioritizes customer requirements and ensures maximum satisfaction through regular client interactions.
    </p>
  </div>
</div>

<hr className="my-8 border border-black" />
<div className="text-center my-10 px-4" data-aos="fade-up">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-indigo-900 mb-4">
            Applications of WPC Door Frames
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 text-indigo-800 text-sm sm:text-base md:text-lg w-full sm:w-[90%] md:w-[80%] mx-auto">
            {['Main Door', 'Industrial Door', 'Bedroom Door', 'Office Door', 'Living Room Door', 'Partition Door'].map((app, index) => (
              <p
                key={index}
                className="bg-indigo-100 rounded-lg p-4 shadow-md"
                data-aos="fade-up"
              >
                {app}
              </p>
            ))}
          </div>
        </div>

<hr className="my-8 border border-black" />

<div className="text-center my-10 px-4" data-aos="fade-up">
  <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-indigo-900 mb-6">
    Tech. Details of WPC Door Frames
  </h1>
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 text-indigo-800">
    {/* Width */}
    <div className="flex flex-col items-center space-y-2">
      <div className="text-2xl md:text-3xl font-bold">⇔</div>
      <h3 className="text-sm md:text-base lg:text-lg font-semibold">Width</h3>
      <p className="text-sm md:text-base lg:text-lg">75x50mm</p>
      <p className="text-sm md:text-base lg:text-lg">100x63mm</p>
      <p className="text-sm md:text-base lg:text-lg">125x63mm</p>
    </div>

    {/* Length */}
    <div className="flex flex-col items-center space-y-2">
      <div className="text-2xl md:text-3xl font-bold">⟷</div>
      <h3 className="text-sm md:text-base lg:text-lg font-semibold">Length</h3>
      <p className="text-sm md:text-base lg:text-lg">Any Length</p>
    </div>

    {/* Surface Treatment */}
    <div className="flex flex-col items-center space-y-2">
      <div className="text-2xl md:text-3xl font-bold">⎈</div>
      <h3 className="text-sm md:text-base lg:text-lg font-semibold">Surface Treatment</h3>
      <p className="text-sm md:text-base lg:text-lg">Sanded</p>
    </div>

    {/* Density */}
    <div className="flex flex-col items-center space-y-2">
      <div className="text-2xl md:text-3xl font-bold">⍜</div>
      <h3 className="text-sm md:text-base lg:text-lg font-semibold">Density</h3>
      <p className="text-sm md:text-base lg:text-lg">900kg/m³</p>
    </div>
  </div>
</div>

    </div>
  );
};

export default HomePage;
