import React, { useState } from 'react';
import { MapPin, Phone, Mail } from 'lucide-react';
import emailjs from 'emailjs-com'; // Import EmailJS library

const ContactPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [summary, setSummary] = useState('');
  const [loading, setLoading] = useState(false); // New state to track loading

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true

    const formData = {
      firstName,
      lastName,
      mobile,
      email,
      summary,
    };

    emailjs
      .send(
        'service_gicc3hx', // Replace with your EmailJS Service ID
        'template_l18w0z9', // Replace with your EmailJS Template ID
        formData,
        'BjiaRWJKq6l2aHrTa' // Replace with your EmailJS Public Key
      )
      .then(
        (result) => {
          alert('Message sent successfully!');
          setLoading(false); // Reset loading after success
        },
        (error) => {
          alert('Failed to send message. Please try again.');
          setLoading(false); // Reset loading after error
        }
      );

    setFirstName('');
    setLastName('');
    setMobile('');
    setEmail('');
    setSummary('');
  };

  return (
    <div className="bg-gradient-to-b from-indigo-50 via-purple-50 to-pink-50 py-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white/80 backdrop-blur-sm shadow-2xl p-6 border border-indigo-100 rounded-xl">
          <div className="space-y-6">
          <div className="flex items-start space-x-4">
              <MapPin className="w-6 h-6 text-purple-500 flex-shrink-0 mt-1" />
              <div>
                <h3 className="text-lg font-medium text-indigo-900">
                  Our Address
                </h3>
                <p className="text-gray-600">
                  Rajkot Morbi Highway, <br />
                  Opp. Arya School, <br />
                  At. Tankara (GUJ.) INDIA
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Phone className="w-6 h-6 text-purple-500 flex-shrink-0" />
              <div>
                <h3 className="text-lg font-medium text-indigo-900">
                  Phone Number
                </h3>
                <p className="text-gray-600">+91 8140166501</p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Mail className="w-6 h-6 text-purple-500 flex-shrink-0" />
              <div>
                <h3 className="text-lg font-medium text-indigo-900">
                  Email Address
                </h3>
                <p className="text-gray-600">
                  info@antiqueplastindustriesllp.com
                </p>
              </div>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              {/* Your form fields */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-indigo-900"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter first name"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="mt-1 block p-2 bg-gray-300 h-[30px] w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-indigo-900"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    placeholder="Enter last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="mt-1 block p-2 bg-gray-300 h-[30px] w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="mobile"
                  className="block text-sm font-medium text-indigo-900"
                >
                  Mobile Number
                </label>
                <input
                  type="tel"
                  id="mobile"
                  placeholder="Enter mobile no."
                  value={mobile}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, '');
                    setMobile(numericValue);
                  }}
                  className="mt-1 block p-2 w-full bg-gray-300 h-[30px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-indigo-900"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block p-2 w-full bg-gray-300 h-[30px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="summary"
                  className="block text-sm font-medium text-indigo-900"
                >
                  Summary
                </label>
                <textarea
                  id="summary"
                  placeholder="Enter your message"
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                  rows={3}
                  className="mt-1 p-2 block w-full bg-gray-300 h-[70%] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="submit"
                className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-indigo-600 to-purple-600 ${
                  loading
                    ? 'cursor-not-allowed opacity-70'
                    : 'hover:from-indigo-700 hover:to-purple-700'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                disabled={loading} // Disable button when loading
              >
                {loading ? 'Submitting...' : 'Submit'} {/* Conditional text */}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
