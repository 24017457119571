import React from 'react';

const GalleryPage = () => {
  const images = [
    { src: "/Images/WhatsApp Image 2024-11-12 at 10.23.00 PM.jpeg" },
    { src: "/Images/WhatsApp Image 2024-11-12 at 10.23.06 PM.jpeg" },
    { src: "/Images/WhatsApp Image 2024-11-12 at 10.23.09 PM.jpeg" },
    { src: "/Images/WhatsApp Image 2024-11-12 at 10.23.08 PM.jpeg" },
  ];

  return (
    <div className="bg-gradient-to-b from-indigo-50 via-purple-50 to-pink-50 py-16 min-h-screen flex justify-center items-center">
      <div className="w-full max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="bg-white/80 backdrop-blur-sm shadow-2xl p-6 sm:p-8 border border-indigo-100 rounded-xl">
          <h2 className="text-2xl sm:text-3xl font-bold bg-gradient-to-r from-indigo-900 to-purple-900 bg-clip-text text-transparent text-center mb-6">
            Our Gallery
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
            {images && images.map((e, index) => (
              <div key={index} className="relative group">
                <img
                  src={e.src}
                  alt=""
                  className="w-full h-auto rounded-lg shadow-lg transition-all duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-indigo-900/30 to-transparent rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryPage;
