import React from "react";
import { MapPin, Phone, Mail, Facebook, Instagram } from "lucide-react";

const Footer = () => {
  return (
    <div className="bg-[#0F172A]">
      {/* Footer */}
      <footer className="relative mt-24 border-t border-white/10">
        {/* Decorative gradient overlay */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-purple-600/10 to-transparent transform scale-150 blur-3xl" />
        </div>

        <div className="relative w-[90%] mx-auto pt-16 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between space-y-8 md:space-y-0">
            {/* Left Section */}
            <div className="space-y-6 w-full md:w-[40%]">
              <div className="flex items-center space-x-2">
                <div className="w-[80px] h-[80px] md:w-[100px] md:h-[100px] rounded-xl bg-gradient-to-r from-purple-500 to-blue-500 flex items-center justify-center">
                  <img src="/Images/logo.jpg" alt="Company Logo" />
                </div>
                <div>
                  <h3 className="text-lg md:text-xl font-bold text-white">
                    Antique Plast Industrial LLP
                  </h3>
                  <p className="text-xs md:text-sm text-gray-400">
                      
                  </p>
                </div>
              </div>

              <p className="text-gray-300 text-sm md:text-base leading-relaxed">
              "Antique Plast Industries - Crafting eco-friendly, durable, and elegant WPC frames, redefining modern interiors with style and sustainability."
              </p>

              {/* Social Links */}
              <div className="flex space-x-4">
                <a
                  href="https://www.facebook.com/profile.php?id=100057103548546&mibextid=LQQJ4d"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <Facebook className="w-5 h-5" />
                </a>
                <a
                  href="https://wa.me/+918140166501"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white transition-colors"
                  aria-label="WhatsApp"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0C5.37 0 0 5.37 0 12c0 2.1.54 4.08 1.48 5.82L0 24l6.35-1.67A11.9 11.9 0 0 0 12 24c6.63 0 12-5.37 12-12S18.63 0 12 0zm0 22c-1.72 0-3.33-.43-4.76-1.19L4 20l1.19-3.25C4.43 15.34 4 13.72 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8-3.59 8-8 8zm4.28-5.7c-.22-.11-1.3-.64-1.5-.71-.2-.08-.34-.11-.49.11s-.56.71-.7.86c-.13.14-.26.16-.48.05-.22-.11-.92-.33-1.76-.95-.65-.47-1.1-1.05-1.23-1.22-.13-.18-.02-.28.1-.39.11-.11.22-.26.33-.39.11-.13.15-.22.22-.37.07-.15.03-.28-.01-.39-.05-.11-.49-1.19-.67-1.63-.18-.43-.36-.37-.5-.38-.13-.01-.28-.01-.43-.01-.15 0-.39.05-.59.28-.2.23-.78.77-.78 1.88s.8 2.17.91 2.33c.11.15 1.56 2.37 3.79 3.33.53.23.95.36 1.27.46.53.17 1.02.15 1.4.09.42-.07 1.3-.53 1.48-1.05.18-.51.18-.95.13-1.05-.05-.09-.2-.14-.42-.25z" />
                  </svg>
                </a>

                <a
                  href="https://www.instagram.com/antiqueplastindustries?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <Instagram className="w-5 h-5" />
                </a>
              </div>
            </div>

            {/* Contact Info */}
            <div className="space-y-6 w-full md:w-[40%]">
              <h4 className="text-xl md:text-3xl font-semibold text-white">
                Contact Us
              </h4>
              <div className="space-y-4">
                <a
                  href="tel:+91-8140166501"
                  className="flex items-center space-x-3 text-gray-300 hover:text-white transition-colors group"
                >
                  <Phone className="w-5 h-5 text-purple-400 group-hover:text-purple-300" />
                  <span>+91 8140166501</span>
                </a>

                <a
                  href="mailto:antiqueplastindustriesllp@gmail.com"
                  className="flex items-center space-x-3 text-gray-300 hover:text-white transition-colors group"
                >
                  <Mail className="w-5 h-5 text-purple-400 group-hover:text-purple-300" />
                  <span>antiqueplastindustriesllp@gmail.com</span>
                </a>
                <div className="flex items-start space-x-3 text-gray-300">
                  <MapPin className="w-5 h-5 text-purple-400 flex-shrink-0 mt-1" />
                  <span>
                    Rajkot Morbi Highway, <br />
                    Opp. Arya School, <br />
                    At. Tankara (GUJ.) INDIA
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="mt-12 pt-8 pb-6 border-t border-white/10">
            <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
              <p className="text-gray-400 text-sm">
                © 2024 Antique plast. All rights reserved.
              </p>
              <p className="text-gray-400 text-sm">Created by Krish Bavarva</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
