import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Component/Footer/Footer';
import HomePage from './Component/Home/HomePage';
import Navbar from './Component/Navbar/Navbar';
import ContactPage from './Component/Contact/ContactPage';
import CertificatePage from './Component/Cirtificate.js/CertificatePage';
import GalleryPage from './Component/Gallery/GalleryPage';
import CustomCursor from './CustomCursor';
import AboutPage from './Component/About/AboutPage';

function App() {
  return (
    <Router>
      <CustomCursor />
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/certificate" element={<CertificatePage />} />
        <Route path="/gallery" element={<GalleryPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
