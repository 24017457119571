import React from 'react';

const CertificatePage = () => {
  return (
    <div className="w-full bg-gradient-to-b from-indigo-50 via-purple-50 to-pink-50 py-16 flex justify-center items-center">
      <div className="bg-white/80 backdrop-blur-sm shadow-2xl p-6 sm:p-8 md:p-12 border border-indigo-100 rounded-xl w-full max-w-md md:max-w-lg lg:max-w-2xl">
        <h2 className="text-2xl sm:text-3xl font-bold bg-gradient-to-r from-indigo-900 to-purple-900 bg-clip-text text-transparent text-center mb-6 md:mb-8">
          Our Certificate
        </h2>
        <div className="flex justify-center">
          <img
            src="/Images/certificate.jpeg"
            alt="Certificate"
            className="w-5/6 sm:w-3/4 md:w-2/3 lg:w-1/2 h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default CertificatePage;
