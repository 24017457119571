import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function AboutPage() {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out' });
  }, []);

  return (
    <>
      <img
        src="/Images/IMG_7265.jpg"
        alt=""
        className="w-full sm:w-[90%] md:w-[80%] lg:w-[70%] mx-auto mt-2 shadow-lg shadow-black"
        data-aos="fade-down"
      />
      <div>
        {/* Quality Policy Section */}
        <div className="text-justify my-10 px-4" data-aos="fade-up">
          <h1 className="text-2xl sm:text-3xl md:text-4xl text-center font-bold text-indigo-900 mb-4">
            QUALITY POLICY
          </h1>
          <div className="text-sm sm:text-base md:text-lg mt-4 text-indigo-800 space-y-4 w-full sm:w-[90%] md:w-[80%] mx-auto">
            <p>ANTIQUE PLAST offers to its customers process solutions and services based on Innovation, Efficiency, and Quality.</p>
            <p>Satisfaction of customer’s requirements and expectations.</p>
            <p>Continuous improvement of the reliability of its products and the Quality Management System with a risk-based thinking purpose.</p>
            <p>Quality Policy provides a framework for setting quality objectives.</p>
            <p>All ANTIQUE Group collaborators and partners must feel committed to pursuing the objectives, to reduce waste and inefficiencies and to prevent errors.</p>
          </div>
        </div>

        <hr className="my-8 border-black" />

        {/* Product Grid Section */}
        <div
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 w-full px-4 sm:w-[90%] md:w-[80%] mx-auto"
          data-aos="fade-up"
        >
          {[
            {
              img: '/Images/4.png',
              title: 'Moulding',
              sizes: '60x30mm, 75x50mm, 90x45mm, 100x63mm, 125x63mm',
            },
            {
              img: '/Images/3.png',
              title: 'Full Pain',
              sizes: '100x63mm',
            },
            {
              img: '/Images/2.png',
              title: 'Moulding With Back Side Rebate',
              sizes: '75x50mm, 100x63mm, 125x63mm',
            },
            {
              img: '/Images/1.png',
              title: 'Double Rabat With Back Side Rebate',
              sizes: '150x63mm',
            },
          ].map((item, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <img src={item.img} alt="" className="w-[80%] sm:w-[80%] md:w-[90%] object-contain" />
              <div className="px-4 mt-4">
                <h2 className="font-bold text-md sm:text-lg md:text-xl">{item.title}</h2>
                <p className="text-sm sm:text-md md:text-lg mt-2">
                  <span className="font-semibold">Available Sizes:</span> <br /> {item.sizes}
                </p>
              </div>
            </div>
          ))}
        </div>

        <hr className="my-8 border-black" />

        {/* Applications Section */}
        <div className="text-center my-10 px-4" data-aos="fade-up">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-indigo-900 mb-4">
            Applications of WPC Door Frames
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 text-indigo-800 text-sm sm:text-base md:text-lg w-full sm:w-[90%] md:w-[80%] mx-auto">
            {['Main Door', 'Industrial Door', 'Bedroom Door', 'Office Door', 'Living Room Door', 'Partition Door'].map((app, index) => (
              <p
                key={index}
                className="bg-indigo-100 rounded-lg p-4 shadow-md"
                data-aos="fade-up"
              >
                {app}
              </p>
            ))}
          </div>
        </div>

        <hr className="my-8 border-black" />

        {/* Technical Details Section */}
        <div className="text-center my-10 px-4" data-aos="fade-up">
  <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-indigo-900 mb-6">
    Tech. Details of WPC Door Frames
  </h1>
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 text-indigo-800">
    {/* Width */}
    <div className="flex flex-col items-center space-y-2">
      <div className="text-2xl md:text-3xl font-bold">⇔</div>
      <h3 className="text-sm md:text-base lg:text-lg font-semibold">Width</h3>
      <p className="text-sm md:text-base lg:text-lg">75x50mm</p>
      <p className="text-sm md:text-base lg:text-lg">100x63mm</p>
      <p className="text-sm md:text-base lg:text-lg">125x63mm</p>
    </div>

    {/* Length */}
    <div className="flex flex-col items-center space-y-2">
      <div className="text-2xl md:text-3xl font-bold">⟷</div>
      <h3 className="text-sm md:text-base lg:text-lg font-semibold">Length</h3>
      <p className="text-sm md:text-base lg:text-lg">Any Length</p>
    </div>

    {/* Surface Treatment */}
    <div className="flex flex-col items-center space-y-2">
      <div className="text-2xl md:text-3xl font-bold">⎈</div>
      <h3 className="text-sm md:text-base lg:text-lg font-semibold">Surface Treatment</h3>
      <p className="text-sm md:text-base lg:text-lg">Sanded</p>
    </div>

    {/* Density */}
    <div className="flex flex-col items-center space-y-2">
      <div className="text-2xl md:text-3xl font-bold">⍜</div>
      <h3 className="text-sm md:text-base lg:text-lg font-semibold">Density</h3>
      <p className="text-sm md:text-base lg:text-lg">750kg/m³</p>
    </div>
  </div>
</div>
            
      </div>
    </>
  );
}
